<template>
  <div class="card col-12">
    <div class="card-body text-center">
      <div class="row">
        <div class="col-12">
          <img
            src="/img/illustrations/template.png"
            alt="..."
            class="img-fluid mt--6 mb-4"
            style="max-width: 150px"
          />

          <!-- <div> -->
          <h2>Respond to customers at lightning speed.</h2>

          <p class="text-muted mb-4">Setup templated responses to boost your productivity.</p>
          <!-- </div> -->

          <router-link :to="{ name: 'create-template' }" class="btn btn-lg btn-primary"
            >Create a Template</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateOffstate',
  props: ['company'],
  computed: {},
}
</script>

<style scoped lang="scss"></style>
