<template>
  <div class="main-content">
    <FadeTransition>
      <FullLoader v-if="!initialized && showLoader"></FullLoader>
      <div v-if="initialized"><slot></slot></div>
    </FadeTransition>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const UserModule = createNamespacedHelpers('user')
const CompanyModule = createNamespacedHelpers('company')
const CustomerProductsModule = createNamespacedHelpers('customerProducts')
const LocationModule = createNamespacedHelpers('location')
import FadeTransition from '@/components/Transitions/FadeTransition'
import FullLoader from '@/components/MainContent/FullLoader'

export default {
  name: 'MainContent',
  components: {
    FullLoader,
    FadeTransition,
  },
  computed: {
    ...UserModule.mapState(['onlineUser']),
    ...CompanyModule.mapState(['activeCompany']),
  },
  data: () => ({
    initialized: false,
    showLoader: false,
  }),
  methods: {
    ...CompanyModule.mapActions(['refreshCompanyList']),
    ...CustomerProductsModule.mapMutations(['resetCheckout']),
    ...LocationModule.mapMutations(['resetLocationSearch']),
  },
  async mounted() {
    const timeout = setTimeout(() => (this.showLoader = true), 500)

    this.resetCheckout()
    this.resetLocationSearch()
    await this.refreshCompanyList()

    clearTimeout(timeout)
    this.initialized = true
  },
}
</script>

<style lang="scss">
.blurred {
  filter: blur(1px);
}
</style>
