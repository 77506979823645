<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="header-pretitle">Winback</h6>
          <h1 class="header-title">Templates</h1>
        </div>
        <div class="col-auto">
          <router-link to="/create-template" class="btn btn-primary">Create Template</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateListHeader',
}
</script>
