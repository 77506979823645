<template>
  <FadeTransition>
    <div v-if="initialized" class="row justify-content-center">
      <div class="col-12 col-lg-10" v-if="shouldShowTemplates">
        <TemplateListHeader />
      </div>
      <div class="col-12 col-lg-10" v-if="shouldShowTemplates">
        <TemplateListContent />
      </div>
      <div class="col-12 col-lg-10 mt-6" v-if="!shouldShowTemplates">
        <TemplateOffstate :company="activeCompany" />
      </div>
    </div>
  </FadeTransition>
</template>

<script>
import FadeTransition from '@/components/Transitions/FadeTransition'
import TemplateListHeader from '@/components/Modules/Template/TemplateListHeader'
import TemplateListContent from '@/components/Modules/Template/TemplateListContent'
import TemplateOffstate from '@/components/Modules/Template/TemplateOffstate'
import { createNamespacedHelpers } from 'vuex'
const TemplateModule = createNamespacedHelpers('template')
const CompanyModule = createNamespacedHelpers('company')
const PromoModule = createNamespacedHelpers('promo')

export default {
  name: 'TemplatesPage',
  components: {
    FadeTransition,
    TemplateListHeader,
    TemplateListContent,
    TemplateOffstate,
  },
  data: () => ({
    initialized: false,
  }),
  computed: {
    ...CompanyModule.mapState(['activeCompany']),
    ...TemplateModule.mapState(['templates']),
    shouldShowTemplates() {
      return this.templates && this.templates.length
    },
  },
  methods: {
    ...TemplateModule.mapActions(['queryTemplates']),
    ...PromoModule.mapActions(['queryPromos']),
  },
  async mounted() {
    await this.queryTemplates()
    await this.queryPromos()
    this.initialized = true
  },
}
</script>

<style scoped lang="scss"></style>
