<template>
  <MainContent>
    <div class="container">
      <TemplateList />
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import TemplateList from '@/components/Modules/Template/TemplateList'
import { trackEvent } from '@/lib/analytics'

export default {
  name: 'TemplatesPage',
  components: {
    MainContent,
    TemplateList,
  },
  mounted() {
    trackEvent(this.$intercom, 'Templates')
  },
}
</script>
